import type { ICompartiment } from "@/interfaces/ICompartiment"
import type { IEvolution } from "@/interfaces/IEvolution"
import type { IGamme } from "@/interfaces/IGamme"
import ProduitService from "@/service/ProduitService"
import { useProfileStore } from "@/store/ProfileStore"
import { defineStore, storeToRefs } from "pinia"
import { ref, watch } from "vue"

export const useProduitStore = defineStore(
  "produit",
  () => {
    const profileStore = useProfileStore()
    const { profile } = storeToRefs(profileStore)

    const gammeService = new ProduitService()
    const gammes = ref<IGamme[]>([])

    async function getGamme() {
      gammes.value = await gammeService.getGamme()

      if (profile.value === 3) {
        gammes.value = gammes.value
          .map((g) => ({
            ...g,
            Portefeuilles: g.Portefeuilles.filter(
              (p) => !(p.Contractuel && !p.Dedie),
            ),
          }))
          .filter((g) => g.Portefeuilles.length)
      }
    }

    function getCompartiment(libelle: string): ICompartiment | undefined {
      return gammes.value
        .flatMap((g) => g.Portefeuilles)
        .flatMap((p) => p.Compartiments)
        .find((c) => c.Libelle === libelle)
    }

    async function getCompartimentEvolution(codeCompartiment: string) {
      const compartiment = gammes.value
        .flatMap((g) => g.Portefeuilles)
        .flatMap((p) => p.Compartiments)
        .find((c) => c.Code === codeCompartiment)

      if (compartiment && compartiment.Evolution === undefined) {
        compartiment.Evolution =
          await gammeService.getCompartimentEvolution(codeCompartiment)
      }

      return compartiment?.Evolution as IEvolution
    }

    watch(profile, getGamme)

    return {
      gammes,
      getGamme,
      getCompartiment,
      getCompartimentEvolution,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
)
