import { o } from "odata"
import type { SpUser } from "../interfaces/IEquipe"

const baseUrl = import.meta.env.VITE_API_ODATA_URL
const apiKey = import.meta.env.VITE_API_ODATA_APIKEY

const odata = o(baseUrl, {
  headers: {
    apiKey: apiKey,
  },
})

export default class EquipeService {
  async get() {
    try {
      const results = await odata.get("Equipe").query()
      return results as Array<SpUser>
    } catch (error) {
      console.error("Error fetching Equipe:", error)
      throw error
    }
  }

  async getSpUser(Id: string) {
    try {
      const results: Array<SpUser> = await odata.get("Equipe").query({
        $filter: `Id eq '${Id}'`,
      })
      return results[0] as SpUser
    } catch (error) {
      console.error(`Error fetching SpUser with Id ${Id}:`, error)
      throw error
    }
  }

  async getSpUsers(Ids: Array<string>) {
    try {
      const spUsers = await Promise.all(Ids.map((id) => this.getSpUser(id)))
      return spUsers
    } catch (error) {
      console.error("Error fetching SpUsers:", error)
      throw error
    }
  }
}
