import { defineStore } from "pinia";
import type { SpUser } from '@/interfaces/IEquipe'
import EquipeService from '@/service/EquipeService';
import { ref } from "vue";

export const useEquipeStore = defineStore('equipe', () => {
    const equipeService = new EquipeService();
    const equipe = ref<SpUser[]>([]);

    async function getEquipe() {
        equipe.value = await equipeService.get();
    }
    async function getSpUser(Id: string) {
        var spUser = equipe.value.find(u => u.Id == Id)
        if (spUser == null) {
            spUser = await equipeService.getSpUser(Id);
            equipe.value.push(spUser)
        }
        return spUser
    }
    return {
        equipe,
        getEquipe,
        getSpUser
    }

}, {
    persist: {
        storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
    }
})