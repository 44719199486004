<script setup lang="ts">
  import { ref } from "vue"

  import AppSubMenu from "./AppSubMenu.vue"
  const model = ref([
    {
      label: "Accueil",
      to: "/",
    },
    {
      label: "Fonds",
      // icon: 'pi pi-home',
      to: "/fonds",
    },
    {
      label: "Philosophie de gestion",
      // icon: 'pi pi-home',
      to: "/philosophieDeGestion",
    },
    {
      label: "Pôles d'investissement",
      // icon: 'pi pi-home',
      to: "/poleInvestissement",
    },
    {
      label: "Finance durable",
      // icon: 'pi pi-home',
      to: "/financeDurable",
    },
    {
      label: "Nous connaître",
      // icon: 'pi pi-home',
      to: "/nousConnaitre",
    },
    {
      label: "Lettres d'investissement",
      // icon: 'pi pi-home',
      to: "/lettresInvestissement",
    },
    {
      label: "Arago",
      to: "/arago",
    },
    {
      label: "Contacts",
      // icon: 'pi pi-home',
      to: "/contacts",
    },
  ])
</script>

<template>
  <AppSubMenu :model="model" />
</template>

<style lang="scss" scoped></style>

