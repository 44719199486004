<script setup lang="ts">
  import { useLayout } from "@/layout/composables/layout"
  import { computed, onBeforeUnmount, onMounted } from "vue"
  import AppBreadCrumb from "./AppBreadcrumb.vue"
  import AppDisclaimer from "./AppDisclaimer.vue"
  import AppFooter from "./AppFooter.vue"
  import AppSidebar from "./AppSidebar.vue"
  import AppTopbar from "./AppTopbar.vue"
  const {
    layoutConfig,
    layoutState,
    watchSidebarActive,
    unbindOutsideClickListener,
  } = useLayout()

  onMounted(() => {
    watchSidebarActive()
  })

  onBeforeUnmount(() => {
    unbindOutsideClickListener()
  })

  const containerClass = computed(() => {
    return {
      "layout-light": !layoutConfig.darkTheme,
      "layout-dark": layoutConfig.darkTheme,
      "layout-colorscheme-menu": layoutConfig.menuTheme === "colorScheme",
      "layout-primarycolor-menu": layoutConfig.menuTheme === "primaryColor",
      "layout-transparent-menu": layoutConfig.menuTheme === "transparent",
      "layout-overlay": layoutConfig.menuMode === "overlay",
      "layout-static": layoutConfig.menuMode === "static",
      "layout-slim": layoutConfig.menuMode === "slim",
      "layout-slim-plus": layoutConfig.menuMode === "slim-plus",
      "layout-horizontal": layoutConfig.menuMode === "horizontal",
      "layout-reveal": layoutConfig.menuMode === "reveal",
      "layout-drawer": layoutConfig.menuMode === "drawer",
      "layout-static-inactive":
        layoutState.staticMenuDesktopInactive &&
        layoutConfig.menuMode === "static",
      "layout-overlay-active": layoutState.overlayMenuActive,
      "layout-mobile-active": layoutState.staticMenuMobileActive,
      "layout-sidebar-active": layoutState.sidebarActive,
      "layout-sidebar-anchored": layoutState.anchored,
    }
  })
</script>

<template>
  <div :class="['layout-container', { ...containerClass }]">
    <AppSidebar />

    <div class="layout-content-wrapper">
      <AppTopbar />
      <AppBreadCrumb class="content-breadcrumb"></AppBreadCrumb>
      <div class="layout-content">
        <router-view></router-view>
      </div>
    </div>
    <AppDisclaimer></AppDisclaimer>
    <Toast></Toast>
    <div class="layout-mask"></div>
  </div>
  <AppFooter></AppFooter>
</template>

