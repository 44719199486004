<script setup lang="ts">
  import { computed } from "vue"
  import { useRoute } from "vue-router"

  const route = useRoute()

  const isHomePage = computed(() => route.name === "Accueil")
</script>

<template>
  <footer
    :class="[
      'footer',
      { 'sticky-footer': isHomePage },
      'bg-surface-950',
      'px-6',
      'md:px-12',
      'lg:px-20',
    ]">
    <div class="py-2 flex flex-col sm:flex-row sm:items-center justify-between">
      <div>
        <img src="/images/FDC_Logo_slogan_blanc.svg" alt="Image" height="80" />
        <div class="mt-2 leading-normal">
          La Finance avec <span class="text-primary">un Point de vue</span>
        </div>
      </div>
      <div class="mt-3 sm:mt-0">
        <ul class="list-none text-white text-xs">
          <li class="mb-2">
            <router-link to="/informationsReglementaires" class="text-white"
              ><i
                class="pi pi-external-link vertical-align-middle mr-2 text-xs"></i
              >Informations r&egrave;glementaires</router-link
            >
          </li>
          <li class="mb-2">
            <router-link to="/avertissement" class="text-white"
              ><i
                class="pi pi-external-link vertical-align-middle mr-2 text-xs"></i
              >Avertissement
            </router-link>
          </li>
          <li class="mb-2">
            <router-link to="/mentionsLegales" class="text-white"
              ><i
                class="pi pi-external-link vertical-align-middle mr-2 text-xs"></i
              >Mentions l&eacute;gales</router-link
            >
          </li>
        </ul>
      </div>
      <div class="mt-3 sm:mt-0">
        <ul class="list-none text-white text-xs">
          <li class="mb-2">
            <a
              class="text-white"
              href="https://www.bing.com/maps?osid=08a394a5-3c59-4b61-9cd1-05228868f3cb&cp=48.868029~2.330294&lvl=16.66&pi=0&imgid=7cc4cc4a-78c2-4809-9410-11466663f19a&v=2&sV=2&form=S00027"
              target="_blank">
              <i class="pi pi-map-marker vertical-align-middle mr-2 text-xs"></i
              >30, avenue de l'Opéra, 75002 Paris</a
            >
          </li>
          <li class="mb-2">
            <i class="pi pi-phone vertical-align-middle mr-2 text-xs"></i
            ><a class="text-white" href="tel:+33153933830">+33 1 53 93 38 30</a>
          </li>
          <li class="mb-2">
            <i class="pi pi-print vertical-align-middle mr-2 text-xs"></i>+33 1
            53 53 99 91
          </li>
          <li class="mb-2">
            <i class="pi pi-envelope vertical-align-middle mr-2 text-xs"></i
            ><a
              class="text-white"
              href="mailto:investisseurs@financieredelacite.com"
              >investisseurs@financieredelacite.com</a
            >
          </li>
        </ul>
      </div>
      <div class="mt-3 sm:mt-0">
        <a
          class="cursor-pointer text-surface-500 dark:text-surface-300 ml-4 transition-colors duration-150 hover:text-surface-700 dark:hover:text-surface-100"
          href="https://www.linkedin.com/company/financiere-de-la-cite-fdc"
          target="_blank"
          title="LinkedIn">
          <i class="pi pi-linkedin" style="font-size: 1.5rem"></i>
        </a>
        <a
          class="cursor-pointer text-surface-500 dark:text-surface-300 ml-4 transition-colors duration-150 hover:text-surface-700 dark:hover:text-surface-100"
          href="https://x.com/FinCite"
          target="_blank"
          title="X">
          <i class="pi pi-twitter" style="font-size: 1.5rem"></i>
        </a>
        <a
          class="cursor-pointer text-surface-500 dark:text-surface-300 ml-4 transition-colors duration-150 hover:text-surface-700 dark:hover:text-surface-100"
          href="https://www.youtube.com/channel/UCXJ3yajEEmo19x4rHSHJB9g"
          target="_blank"
          title="Youtube">
          <i class="pi pi-youtube" style="font-size: 1.5rem"></i>
        </a>
        <!-- <a
          class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
          href="https://patrimeta.com"
          target="_blank">
          <img
            src="/images/patrimeta.png"
            alt="Image"
            height="20px"
            width="20px" />
        </a> -->
      </div>
    </div>
  </footer>
</template>

<style scoped>
  .footer {
    width: 100%;
  }

  /* .sticky-footer {
    position: sticky;
    bottom: 0;
  } */

  @media (min-width: 768px) {
    .sticky-footer {
      position: sticky;
      bottom: 0;
    }
  }
</style>
