import { defineStore } from "pinia";
import { ref } from "vue";

export const useProfileStore = defineStore('profile', () => {
    const profile = ref<number>();
    return {
        profile
    }

}, {
    persist: {
        storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
    }
})