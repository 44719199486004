import AppLayout from "@/layout/AppLayout.vue"
import { createRouter, createWebHistory } from "vue-router"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: AppLayout,
      children: [
        {
          path: "/",
          name: "Accueil",
          meta: {
            breadcrumb: ["Accueil"],
          },
          component: () => import("@/views/Accueil.vue"),
        },
        {
          path: "/fonds/:libelle?",
          name: "fonds",
          meta: {
            breadcrumb: ["Fonds"],
          },
          props: true,
          component: () => import("@/views/Fonds.vue"),
        },
        {
          path: "/philosophieDeGestion",
          name: "philosophieDeGestion",
          meta: {
            breadcrumb: ["Philosophie de gestion"],
          },
          props: true,
          component: () => import("@/views/PhilosophieDeGestion.vue"),
        },
        {
          path: "/poleInvestissement",
          name: "poleInvestissement",
          meta: {
            breadcrumb: ["Pôles d'investissement"],
          },
          component: () => import("@/views/PoleInvestissement.vue"),
        },
        {
          path: "/financeDurable",
          name: "financeDurable",
          meta: {
            breadcrumb: ["Finance durable"],
          },
          component: () => import("@/views/FinanceDurable.vue"),
        },
        {
          path: "/lettresInvestissement",
          name: "lettresInvestissement",
          meta: {
            breadcrumb: ["Lettres d'investissement"],
          },
          component: () => import("@/views/LettresInvestissement.vue"),
        },
        {
          path: "/nousConnaitre",
          name: "nousConnaitre",
          meta: {
            breadcrumb: ["Nous connaître"],
          },
          component: () => import("@/views/NousConnaitre.vue"),
        },
        {
          path: "/arago",
          name: "Arago",
          meta: {
            breadcrumb: ["Arago"],
          },
          component: () => import("@/views/Arago.vue"),
        },
        {
          path: "/contacts",
          name: "contacts",
          meta: {
            breadcrumb: ["Contacts"],
          },
          component: () => import("@/views/Contacts.vue"),
        },
        {
          path: "/mentionsLegales",
          name: "mentionsLegales",
          meta: {
            breadcrumb: ["Mentions légales"],
          },
          component: () => import("@/views/MentionsLegales.vue"),
        },
        {
          path: "/avertissement",
          name: "avertissement",
          meta: {
            breadcrumb: ["Avertissement"],
          },
          component: () => import("@/views/Avertissement.vue"),
        },
        {
          path: "/informationsReglementaires",
          name: "informationsReglementaires",
          meta: {
            breadcrumb: ["Informations règlementaires"],
          },
          component: () => import("@/views/InformationsReglementaires.vue"),
        },
        {
          path: "/download/:filename",
          name: "Download",
          component: () => import("@/components/DownloadHandler.vue"),
        },
      ],
    },
  ],
})

export default router
