import type { IEvolution } from "@/interfaces/IEvolution"
import { o } from "odata"
import type { IGamme } from "../interfaces/IGamme"

const baseUrl = import.meta.env.VITE_API_ODATA_URL
const apiKey = import.meta.env.VITE_API_ODATA_APIKEY

const odata = o(baseUrl, {
  headers: {
    apiKey: apiKey,
  },
})

export default class ProduitService {
  async getGamme(): Promise<IGamme[]> {
    const query = odata.get("Gamme").query({
      $filter: "codeGamme ne 'NonClassifie'",
      $select: "Libelle,Slogan",
      $expand:
        "Portefeuilles($filter=Categorie eq 'Fonds ouvert';$select=ArticleSFDR,Categorie,ClassificationAMF,Code,CommissionMouvement,Contractuel,Compartiments,DateCreation,Dedie,Depositaire,FormeJuridique,FrequenceValorisation,HorizonInvestissement,Libelle,OrientationGestion,Repartitions,Slogan;$expand=Compartiments($select=Code,Devise,Libelle,DateCreation,DateValorisation,DroitsEntreeNonAcquis,FraisGestionFixes,PerformancesCumulees,PerformancesAnnualisees,PerformancesAnnuelles,SRI,TypePart,ValeurLiquidative),Gestion($select=Id))",
    })

    const results = (await query) as IGamme[]
    return results
  }

  async getCompartimentEvolution(
    codeCompartiment: string,
  ): Promise<IEvolution> {
    const query = odata.get("Gamme").query({
      $select: "Portefeuilles",
      $expand: `Portefeuilles($select=Compartiments;$expand=Compartiments($select=Evolution;$filter=Code eq '${codeCompartiment}'))`,
    })

    const results = (await query) as IGamme[]
    const evolution = results.flatMap((g) =>
      g.Portefeuilles.flatMap((p) =>
        p.Compartiments.flatMap((c) => c.Evolution),
      ),
    )
    return evolution[0]
  }
}
