<script setup lang="ts">
  import { useProfileStore } from "@/store/ProfileStore"
  import { computed } from "vue"
  const profileStore = useProfileStore()
  const visible = computed(() => profileStore.profile === undefined)
  const setProfile = (profile: number) => {
    profileStore.profile = profile
  }
</script>

<template>
  <Dialog
    v-model:visible="visible"
    append-to="body"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '96vw' }"
    :style="{ width: '700px' }"
    :closable="false"
    :show-header="false">
    <div class="flex flex-col items-center my-6">
      <span
        class="w-16 h-16 flex items-center justify-center bg-highlight text-highlight-contrast mr-4 rounded-full mb-4">
        <i class="pi pi-question text-3xl"></i>
      </span>
      <div class="font-medium text-2xl text-surface-900 dark:text-surface-0">
        Merci d'indiquer votre profil d'investisseur
      </div>
    </div>

    <template #footer>
      <div class="border-t border-surface pt-4 flex w-full gap-2">
        <Button
          label="Institutionnels et entreprises"
          class="w-4/12"
          @click="setProfile(1)"></Button>
        <Button
          label="Professionnels du patrimoine"
          class="w-4/12"
          @click="setProfile(2)"></Button>
        <Button
          label="Clients particuliers"
          class="w-4/12"
          @click="setProfile(3)"></Button>
      </div>
    </template>
  </Dialog>
</template>
